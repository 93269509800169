import React from 'react';
import { observer } from 'mobx-react-lite';
import { useFormContext } from 'react-hook-form';

import {
  getMaxDigitWithDot,
  getStepBasedOnTickSize,
  getTradingAmountTranslationKey,
} from '@trader/utils';
import { TInstrumentEntity, useMst } from '@trader/store';
import { Controller } from '@trader/components';
import { useI18next } from '@trader/services';
import { ICommonOrderType } from '@trader/types';

import { Protection } from '../Protection';
import { PurchaseDetails } from '../PurchaseDetails';
import { PlaceOrderButton } from '../PlaceOrderButton';
import { SideButtons } from '../sideButtons';
import * as Styled from './styled';

interface ILimitOrder extends ICommonOrderType {
  buttonTitle?: string;
  isEditingOrder?: boolean;
  fullWidth?: boolean;
  onClick?: () => void;
}

export const LimitOrder: React.FC<ILimitOrder> = observer(
  ({ type, buttonTitle, isEditingOrder = false, fullWidth }) => {
    const store = useMst();
    const { translate } = useI18next();
    const { control, register, formState } = useFormContext();

    const trading = store.trading.getTrading(type);
    const instrument = trading.instrument as TInstrumentEntity;

    const isInvestmentAccProduct = store.user.isInvestmentAccProduct();

    const step = getStepBasedOnTickSize(
      instrument.pipSize,
      instrument.tickSize
    );

    return (
      <React.Fragment>
        <Styled.Root>
          {!isInvestmentAccProduct && <SideButtons type={type} />}
          <Styled.Main>
            <Controller
              type='tradingInput'
              isFocus
              fullWidth={fullWidth}
              customLabel={translate(
                trading.side
                  ? `COMMON.LABELS.${trading.side?.toUpperCase()}_WHEN`
                  : 'COMMON.LABELS.PRICE'
              )}
              disabled={false}
              minValue={0}
              maxValue={0}
              fixDigitAfterDot={getMaxDigitWithDot(instrument.pipSize)}
              step={step}
              onCustomChange={() =>
                trading.updateEditMode('priceEditMode', 'Manual')
              }
              control={control}
              {...register('price')}
            />
          </Styled.Main>
          <Styled.Amount>
            <Controller
              type='tradingInput'
              shouldCheckActivityOfButtons
              fullWidth={fullWidth}
              customLabel={translate(
                getTradingAmountTranslationKey(trading.tradingType)
              )}
              disabled={isEditingOrder}
              minValue={trading.minAmount()}
              maxValue={trading.maxAmount()}
              fixDigitAfterDot={trading.amountDigitsAfterDot}
              step={trading.step()}
              control={control}
              {...register('amount')}
            />
          </Styled.Amount>
          <PurchaseDetails type={type} />
        </Styled.Root>
        <Protection type={type} fullWidth={fullWidth} />
        <PlaceOrderButton
          type={type}
          isDisabled={!formState.isValid || trading.isRequiredOpenCostError}
          buttonTitle={buttonTitle}
        />
      </React.Fragment>
    );
  }
);
