import React from 'react';
import { observer } from 'mobx-react-lite';

import {
  TInstrumentEntity,
  TModalStore,
  TPositionMetricEntity,
  useMst,
} from '@trader/store';
import { defaultIcon } from '@trader/constants';
import { TPlaceOrderSide } from '@trader/types';
import { useI18next } from '@trader/services';
import { Typography } from '@trader/components';

import * as Styled from './styled';

interface IOrderResponse {
  symbol: string;
  volume: number;
  price: number;
  isClose: boolean;
  billType: TPlaceOrderSide;
  orderId: number;
}

export const OrderResponse: React.FC = observer(() => {
  const store = useMst();
  const { translate } = useI18next();
  const modal: TModalStore = store.ui.modal;

  const model: IOrderResponse = {
    symbol: modal.options.get('symbol') ?? '',
    volume: modal.options.get('volume') ?? 0,
    price: modal.options.get('price') ?? 0,
    isClose: modal.options.get('isClose') ?? false,
    billType: modal.options.get('billType')?.toUpperCase() ?? 'BUY',
    orderId: modal.options.get('orderId') ?? 0,
  };

  if (model.orderId > 0) {
    const positionMetric =
      store.entities.positionsMetrics.get<TPositionMetricEntity>(model.orderId);

    if (positionMetric) {
      model.billType = positionMetric.side;
    }
  }

  const isInvestmentAccProduct = store.user.isInvestmentAccProduct();

  const subTitle = model.isClose
    ? translate(`COMMON.LABELS.CLOSE_${model.billType?.toUpperCase()}`)
    : translate(`COMMON.LABELS.OPENED_${model.billType?.toUpperCase()}`);

  const instrument = store.entities.instruments.get<TInstrumentEntity>(
    model.symbol
  );

  return (
    <Styled.Root $isInvestmentAccProduct={isInvestmentAccProduct}>
      <Styled.Header>
        <Typography variant='heading' fontWeight='bold'>
          {translate('COMMON.LABELS.ORDER_PLACED')}
        </Typography>
      </Styled.Header>
      <img
        src={instrument?.iconUrl || defaultIcon}
        alt={model.symbol}
        width={30}
        height={30}
      />
      <Styled.VolumeContainer>
        {subTitle}
        <Styled.Success variant='heading' fontWeight='bold' marginLeft='5px'>
          {model.volume}
        </Styled.Success>
      </Styled.VolumeContainer>
      <Typography variant='xl' fontWeight='bold'>
        {model.symbol}
      </Typography>
      <Typography variant='medium'>
        {model.price ? `@${model.price}` : ''}
      </Typography>
      <Styled.Success variant='xl'>
        {translate('COMMON.LABELS.SUCCESS')}
      </Styled.Success>
    </Styled.Root>
  );
});
