import React from 'react';
import { observer } from 'mobx-react-lite';
import { useFormContext } from 'react-hook-form';

import {
  calculateSpread,
  getMaxDigitWithDot,
  getStepBasedOnTickSize,
  getTradingAmountTranslationKey,
} from '@trader/utils';
import { TInstrumentEntity, useMst } from '@trader/store';
import { Controller } from '@trader/components';
import { useI18next } from '@trader/services';

import { Protection } from '../Protection';

import * as Styled from './styled';
import { ICommonOrderType } from '@trader/types';
import { BuyButton, PlaceOrderButton, PurchaseDetails } from '@trader/trading';

interface ILimitOrder extends ICommonOrderType {
  buttonTitle?: string;
  isEditingOrder?: boolean;
}

export const LimitOrder: React.FC<ILimitOrder> = observer(
  ({ type, buttonTitle, isEditingOrder = false }) => {
    const store = useMst();
    const { translate } = useI18next();
    const { control, register, formState } = useFormContext();

    const trading = store.trading.getTrading(type);
    const instrument = trading.instrument as TInstrumentEntity;

    const isInvestmentAccProduct = store.user.isInvestmentAccProduct();

    const step = getStepBasedOnTickSize(
      instrument.pipSize,
      instrument.tickSize
    );

    return (
      <React.Fragment>
        <Styled.Root>
          {!isInvestmentAccProduct && (
            <Styled.ActionButtons>
              <BuyButton
                type={type}
                isDisabled={isEditingOrder}
                isAsk
                side='Sell'
              />
              <Styled.Spread>
                {calculateSpread({
                  ask: Number(instrument.ask),
                  bid: Number(instrument.bid),
                  pipSize: instrument.pipSize,
                })}
              </Styled.Spread>
              <BuyButton type={type} isDisabled={isEditingOrder} side='Buy' />
            </Styled.ActionButtons>
          )}
          <Styled.Main>
            <Styled.Title>
              {translate(`COMMON.LABELS.${trading.side?.toUpperCase()}_WHEN`)}
            </Styled.Title>
            <Controller
              type='orderInput'
              isFocus
              placeholder={translate('COMMON.LABELS.PRICE')}
              disabled={false}
              minValue={0}
              maxValue={0}
              fixDigitAfterDot={getMaxDigitWithDot(instrument.pipSize)}
              step={step}
              control={control}
              {...register('price')}
            />
          </Styled.Main>
          <Styled.Main>
            <Styled.Title>
              {translate(getTradingAmountTranslationKey(trading.tradingType))}
            </Styled.Title>
            <Controller
              type='orderInput'
              shouldCheckActivityOfButtons
              placeholder={translate(
                getTradingAmountTranslationKey(trading.tradingType)
              )}
              disabled={isEditingOrder}
              minValue={trading.minAmount()}
              maxValue={trading.maxAmount()}
              fixDigitAfterDot={trading.amountDigitsAfterDot}
              step={trading.step()}
              control={control}
              {...register('amount')}
            />
          </Styled.Main>
          <PurchaseDetails type={type} />
        </Styled.Root>
        <Protection type={type} />
        <PlaceOrderButton
          type={type}
          isDisabled={!formState.isValid || trading.isRequiredOpenCostError}
          buttonTitle={buttonTitle}
        />
      </React.Fragment>
    );
  }
);
