import React, { useCallback, useEffect } from 'react';
import { useTheme } from '@mui/material';

import { adapterLineStyle, emptyFn, MODAL_TYPES } from '@trader/constants';
import { TPositionMetricEntity, useMst } from '@trader/store';
import { getNetPl } from '@trader/utils';
import { useI18next } from '@trader/services';

import {
  IChartingLibraryWidget,
  IPositionLineAdapter,
} from '../../charting_library';
import { useGetAdapterDiff } from '../muliBands/useGetAdapterDiff';
import { useDisplayPositionProtection } from './useDisplayPositionProtection';

interface IPosition {
  key: string;
  ref: IPositionLineAdapter | undefined;
}

const positionLineLength = 50;
const positions = new Map<string, IPosition>();

export const useDisplayAllPositions = (
  widget: React.MutableRefObject<IChartingLibraryWidget | null>
) => {
  const store = useMst();
  const theme = useTheme();
  const { translate } = useI18next();

  const { typeColor } = useGetAdapterDiff('Position');

  const {
    createPositionProtection,
    updatePositionProtection,
    clearPositionProtection,
  } = useDisplayPositionProtection(widget);

  const trading = store.trading.getTrading('createOrder');

  const positionsMetrics =
    store.entities.positionsMetrics.getAll<TPositionMetricEntity>();

  const symbol =
    (trading.instrument?.symbol as string) ||
    store.pages.trading.getInstrumentSymbolByLayout();

  const shouldDisplayAllPositionsOnChart =
    !trading.side && store.ui.tradingView.shouldDisplayAllLineAdapters;

  const symbolPositionsMetrics = positionsMetrics.filter(
    metric => metric.symbol === symbol && metric.showOnTradingViewChart
  );

  const shouldRender =
    store.ui.tradingView.isChartLoaded && shouldDisplayAllPositionsOnChart;

  const createPosition = (id: string) => {
    createPositionProtection(id);

    const position =
      store.entities.positionsMetrics.get<TPositionMetricEntity>(id);

    const currentPl = getNetPl(position?.pl, '');

    const ref = widget?.current
      ?.activeChart()
      .createPositionLine()
      .setText(currentPl.stringValue)
      .setBodyTextColor(currentPl.textColor)
      .setCloseTooltip(translate('COMMON.LABELS.CLOSE_POSITION'))
      .setCloseButtonBorderColor(typeColor(id, position?.side))
      .setCloseButtonBackgroundColor(typeColor(id, position?.side))
      .setBodyBorderColor(typeColor(id, position?.side))
      .setBodyBackgroundColor(typeColor(id, position?.side))
      .setCloseButtonIconColor(theme.palette.white.main)
      .setLineColor(typeColor(id, position?.side))
      .setLineLength(positionLineLength)
      .setLineStyle(adapterLineStyle)
      .setQuantity(
        `${translate(`COMMON.LABELS.${position?.side?.toUpperCase()}`)}: ${
          position?.quantity
        }; ID: ${position?.positionId}`
      )
      .setQuantityBackgroundColor(theme.palette.tab.light)
      .setQuantityBorderColor(theme.palette.tab.light)
      .onClose(function () {
        store.ui.modal.open(MODAL_TYPES.closePosition, {
          symbol: position?.symbol,
          positionId: position?.positionId,
          side: position?.side,
        });
      })
      .onModify(function () {
        store.ui.modal.open(MODAL_TYPES.addProtection, {
          symbol: position?.symbol,
          positionId: position?.positionId,
          side: position?.side,
        });
      })
      .setPrice(position?.openPrice);

    positions.set(id, {
      ...positions.get(id),
      key: id,
      ref,
    });
  };

  const clearPositions = useCallback(() => {
    Array.from(positions.values()).forEach(position => {
      position?.ref?.remove();
    });
    positions.clear();

    clearPositionProtection();
  }, []);

  // initialize positions
  useEffect(() => {
    if (shouldRender) {
      widget.current?.onChartReady(() => {
        symbolPositionsMetrics.forEach(metric => {
          if (!metric) {
            return;
          }
          if (!positions.get(metric.positionId)) {
            createPosition(metric.positionId);
          }
        });
      });
    }
    return () => {
      clearPositions();
    };
  }, [shouldRender, symbol]);

  // create & update positions
  useEffect(() => {
    if (shouldRender) {
      widget.current?.onChartReady(() => {
        const isDataReady = widget.current?.activeChart().dataReady(emptyFn);
        if (isDataReady) {
          symbolPositionsMetrics.forEach(metric => {
            const pl = metric.pl;

            const currentPl = getNetPl(pl, '');
            const positionRef = positions.get(metric?.positionId)?.ref;
            if (positionRef) {
              positionRef?.setText(`${currentPl.stringValue}`);
              positionRef.setQuantity(
                `${translate(
                  `COMMON.LABELS.${metric?.side?.toUpperCase()}`
                )}: ${metric?.quantity}; ID: ${metric?.positionId}`
              );
              positionRef?.setBodyTextColor(currentPl.textColor);
              updatePositionProtection(metric?.positionId);
            } else {
              createPosition(metric?.positionId);
            }
          });
        }
      });
    }
  }, [
    JSON.stringify(symbolPositionsMetrics),
    shouldDisplayAllPositionsOnChart,
    symbol,
  ]);

  // delete position
  useEffect(() => {
    Array.from(positions.values()).forEach((position: IPosition) => {
      const matchedPosition =
        store.entities.positionsMetrics.get<TPositionMetricEntity>(
          position.key
        );
      if (!matchedPosition || !matchedPosition.showOnTradingViewChart) {
        positions.get(position.key)?.ref?.remove();
        positions.delete(position.key);
        updatePositionProtection(position.key);
      }
    });
  }, [symbolPositionsMetrics.length]);

  useEffect(() => {
    if (!shouldDisplayAllPositionsOnChart) {
      clearPositions();
    }
  }, [shouldDisplayAllPositionsOnChart]);
};
