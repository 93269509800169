import React from 'react';
import { observer } from 'mobx-react-lite';

import { IconButton, Popover, Select, TextField } from '@trader/components';
import { TCreateTableModelInstance } from '@trader/store';
import { EFilterType } from '@trader/constants';

import * as Styled from './styled';
import { useI18next } from '@trader/services';

interface ISortingButton {
  columnId: string;
  customFilter?: () => React.ReactNode;
  columnType?: EFilterType;
  isFilterable?: boolean;
  tableStore: TCreateTableModelInstance;
}

const FilteringButton: React.FC<ISortingButton> = observer(
  ({ columnId, tableStore }) => {
    const isActive =
      (tableStore.filtering?.column.id === columnId &&
        !!tableStore.filtering.value) ||
      !!tableStore.getCustomFilter(columnId);

    return (
      <Styled.FilteringButton
        $isActive={isActive}
        className={!isActive ? 'Sort_Filter_Button_Hover' : undefined}
        size='small'
        iconType='filter'
        onClick={() => tableStore.openFiltering(columnId)}
      />
    );
  }
);

const FilteringContent: React.FC<
  Pick<ISortingButton, 'tableStore' | 'columnType'>
> = observer(({ tableStore, columnType }) => {
  const { translate } = useI18next();

  return (
    <Styled.Root>
      {tableStore.filtering.value ? (
        <IconButton
          iconType='remove'
          onClick={tableStore.clearFilteringValue}
        />
      ) : null}
      <Select
        name='operator'
        label={translate('FILTERS.OPERATOR')}
        value={[
          {
            ...tableStore.filtering.operator,
            title: translate(tableStore.filtering.operator.title),
          },
        ]}
        onChange={event =>
          tableStore.changeFiltering(event.target.name, event.target.value)
        }
        options={tableStore.operators.map(o => ({
          ...o,
          title: translate(o.title),
        }))}
      />
      <TextField
        autoFocus
        name='value'
        label={translate('COMMON.LABELS.VALUE')}
        value={tableStore.filtering.value}
        onChange={event =>
          tableStore.changeFiltering(event.target.name, event.target.value)
        }
        keyboardType={columnType === EFilterType.NUMBER ? 'number' : 'text'}
        shouldHideControlInfo
      />
    </Styled.Root>
  );
});

export const Filtering: React.FC<ISortingButton> = observer(
  ({ columnId, columnType, isFilterable, tableStore, customFilter }) => {
    if (!isFilterable) {
      return null;
    }

    return (
      <Styled.FilterContainer>
        <Popover
          behavior='click'
          elevation={4}
          trigger={
            <FilteringButton columnId={columnId} tableStore={tableStore} />
          }
          content={
            customFilter?.() || (
              <FilteringContent
                columnType={columnType}
                tableStore={tableStore}
              />
            )
          }
          transformOrigin={{
            vertical: 0,
            horizontal: 10,
          }}
        />
      </Styled.FilterContainer>
    );
  }
);
