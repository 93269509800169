import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';

import { IChangeFieldEvent, TOptions } from '@trader/types';
import { Stack } from '@trader/components';
import { themes } from '@trader/themes';
import { useMst } from '@trader/store';
import {
  shouldDisplayEdgeX,
  shouldDisplayMuliBands,
  tradingViewTypes,
} from '@trader/constants';
import {
  useI18next,
  getLanguageOption,
  getLanguageOptions,
  LANGUAGES,
} from '@trader/services';

import { IndicatorSwitcher } from './indicatorSwitcher';

import * as Styled from './styled';
import { SaveButton } from '../../desktop.styled';

const MINIMUM_LANGUAGE_OPTIONS = 2;

export const GeneralTab: React.FC = observer(() => {
  const store = useMst();
  const { translate, changeLng } = useI18next();

  const [state, setState] = useState({
    theme: [
      {
        title: translate(`COMMON.LABELS.${store.app.themeMode.toUpperCase()}`),
        value: store.app.themeMode,
      },
    ],
    chart: [
      {
        title: translate(`COMMON.LABELS.${store.app.chartType.toUpperCase()}`),
        value: store.app.chartType,
      },
    ],
    language: [getLanguageOption()],
    isTradingCentral: store.user.settings.isDisplayedByUser,
    isEdgeX: store.user.settings.isEdgeXDisplayedByUser,
    isXStrategy: store.user.settings.isXStrategyDisplayedByUser,
    isEdited: false,
  });

  const themeOptions: TOptions = themes.map(t => ({
    title: translate(`COMMON.LABELS.${t.toUpperCase()}`),
    value: t,
  }));

  const chartOptions: TOptions = tradingViewTypes.map(c => ({
    title: translate(`COMMON.LABELS.${c.toUpperCase()}`),
    value: c,
  }));

  const languageOptions: TOptions = getLanguageOptions();
  const hasLanguageSwitcher: boolean =
    languageOptions.length >= MINIMUM_LANGUAGE_OPTIONS;

  const handleChange = (
    event: IChangeFieldEvent<TOptions> | React.ChangeEvent<HTMLInputElement>
  ) => {
    const switchers = ['isTradingCentral', 'isEdgeX', 'isXStrategy'];
    const value = !switchers.includes(event.target.name)
      ? event.target.value
      : (event as React.ChangeEvent<HTMLInputElement>).target.checked;

    setState(prev => ({
      ...prev,
      isEdited: true,
      [event.target.name]: value,
    }));
  };

  const handleSave = () => {
    const translations = LANGUAGES[state.language[0].value].resource;
    const languageTranslatedKey = LANGUAGES[state.language[0].value].name;
    const translatedLanguageOption = {
      title: translations.LANGUAGES[`${languageTranslatedKey.toUpperCase()}`],
      value: state.language[0].value,
    };

    setState(prev => ({
      ...prev,
      language: [translatedLanguageOption],
      isEdited: false,
    }));

    const settings = {
      isTradingCentral: state.isTradingCentral,
      isEdgeX: state.isEdgeX,
      isXStrategy: state.isXStrategy,
    };
    store.app.toggleThemeMode(state.theme[0].value);
    store.app.changeChartType(state.chart[0].value);
    store.user.updateSettingsAsync.run(settings);
    changeLng(state.language[0].value);
  };

  return (
    <Styled.Root>
      <Styled.Content>
        <Styled.Skin>
          <Styled.Title>{translate('COMMON.LABELS.SKIN')}</Styled.Title>
          <Styled.Item>
            <Styled.Label>{translate('COMMON.LABELS.THEME')}</Styled.Label>
            <Styled.Select
              fullWidth={false}
              name='theme'
              value={[
                {
                  title: translate(
                    `COMMON.LABELS.${state.theme[0].value.toUpperCase()}`
                  ),
                  value: state.theme[0].value,
                },
              ]}
              options={themeOptions}
              onChange={handleChange}
            />
          </Styled.Item>
          <Styled.Item>
            <Styled.Label>{translate('COMMON.LABELS.CHART')}</Styled.Label>
            <Styled.Select
              fullWidth={false}
              name='chart'
              value={[
                {
                  title: translate(
                    `COMMON.LABELS.${state.chart[0].value.toUpperCase()}`
                  ),
                  value: state.chart[0].value,
                },
              ]}
              options={chartOptions}
              onChange={handleChange}
            />
          </Styled.Item>
          <Styled.Item>
            <Styled.Label>{translate('COMMON.LABELS.LANGUAGE')}</Styled.Label>
            <Styled.Select
              fullWidth={false}
              name='language'
              disabled={!hasLanguageSwitcher}
              value={state.language}
              options={languageOptions}
              onChange={handleChange}
            />
          </Styled.Item>
        </Styled.Skin>
        <Styled.ExtraFeatures>
          <Styled.Title>
            {translate('COMMON.LABELS.EXTRA_FEATURES')}
          </Styled.Title>
          <Stack direction='column' spacing='18px'>
            <IndicatorSwitcher
              shouldDisplay
              disabled={!store.user.settings.isCrmTradingCentral}
              checked={state.isTradingCentral}
              name='isTradingCentral'
              leftLabel='COMMON.LABELS.TRADING_CENTRAL'
              innerHtml='COMMON.LABELS.TRADING_CENTRAL_IS_DISABLED'
              onChange={handleChange}
            />
            <IndicatorSwitcher
              shouldDisplay={shouldDisplayEdgeX}
              disabled={!store.user.settings.isCrmEdgeX}
              checked={state.isEdgeX}
              name='isEdgeX'
              leftLabel='COMMON.LABELS.EDGE_X_INDICATOR'
              innerHtml='COMMON.LABELS.EDGE_X_INDICATOR_IS_DISABLED'
              onChange={handleChange}
            />
            <IndicatorSwitcher
              shouldDisplay={
                shouldDisplayMuliBands && store.user.settings.isCrmXStrategy
              }
              disabled={!store.user.settings.isCrmXStrategy}
              checked={state.isXStrategy}
              name='isXStrategy'
              leftLabel='COMMON.LABELS.MULI_BANDS_STRATEGY'
              innerHtml='COMMON.LABELS.MULI_BANDS_INDICATOR_IS_DISABLED'
              onChange={handleChange}
            />
          </Stack>
        </Styled.ExtraFeatures>
      </Styled.Content>
      <SaveButton disabled={!state.isEdited} onClick={handleSave}>
        {translate('COMMON.LABELS.SAVE_CHANGES')}
      </SaveButton>
    </Styled.Root>
  );
});
