import React, { forwardRef } from 'react';

import { EOrderSide, IOrderMessage } from '@trader/types';
import { useI18next } from '@trader/services';

import { INotificationProps } from '../types';

import * as Styled from '../styled';

export const OrderDeleted: React.FC<INotificationProps<IOrderMessage>> =
  forwardRef<HTMLDivElement, INotificationProps<IOrderMessage>>(
    ({ closeSnackbar, message }, ref) => {
      const { translate } = useI18next();

      const side = EOrderSide[message?.side];
      const translatedSide = side
        ? translate(`COMMON.LABELS.${side.toUpperCase()}`)
        : side;

      return (
        <Styled.NotificationRoot ref={ref}>
          <Styled.Close onClick={closeSnackbar} iconType='remove' />
          <Styled.SuccessIcon iconType='challengeCompleted' />
          <Styled.Content>
            <Styled.Title>
              {translate('NOTIFICATIONS.ORDER_DELETED')}
            </Styled.Title>
            <Styled.Text>
              {translatedSide} {message?.volume} {message?.symbol}{' '}
              {translate('COMMON.LABELS.AT')}{' '}
              {message?.stopPrice || message?.limitPrice}
            </Styled.Text>
          </Styled.Content>
        </Styled.NotificationRoot>
      );
    }
  );
