import React from 'react';
import { observer } from 'mobx-react-lite';

import { EAccountTypeNames, isChallengeAccountType } from '@trader/constants';
import { Icon, Typography, Stack } from '@trader/components';
import { TTradingAccountEntity, useMst } from '@trader/store';
import { useI18next } from '@trader/services';
import { useExtendItemWidthDependsLng } from '@trader/hooks';

import { useAccountInformationData } from './useAccountInformationData';
import { useAccountCalculatedValues } from './useAccountCalculatedValues';
import { AddFundsButton } from './addFundsButton';
import { ChangeAccountButton } from './changeAccountButton';
import { ChangeLeverageButton } from './changeLeverageButton';
import { AccountStatementButton } from './accountStatementButton';
import * as Styled from './desktop.styled';

export const DesktopAccountInformation: React.FC = observer(() => {
  const { accounts } = useAccountInformationData();

  return (
    <Styled.Root>
      <Stack direction='column' spacing='46px' padding='6px 18px 18px 18px'>
        {accounts.map(account => (
          <Account key={account.platformLogin} account={account} />
        ))}
      </Stack>
    </Styled.Root>
  );
});

const Account: React.FC<{
  account: TTradingAccountEntity;
}> = observer(({ account }) => {
  const { translate } = useI18next();
  const { getExtendedWidth } = useExtendItemWidthDependsLng();
  const accountCalculatedValues = useAccountCalculatedValues(account);

  const store = useMst();
  const challenge = store.user.getAccountChallenge(account.tradingId);

  const isLiveAccount = account.accountType === EAccountTypeNames.Live;
  const shouldShowLeverage = import.meta.env.VITE_DISPLAY_LEVERAGE === 'true';

  return (
    <Styled.Account>
      <Stack
        direction='row'
        alignItems='center'
        spacing='8px'
        marginBottom='10px'
      >
        <Icon iconType='account' />
        <Typography variant='medium' fontWeight='medium'>
          {accountCalculatedValues.accountId}
        </Typography>
        {!isChallengeAccountType && (
          <>
            <Styled.Badge>{account.product}</Styled.Badge>
            <Styled.Badge>
              {translate(`COMMON.LABELS.${account.accountType.toUpperCase()}`)}
            </Styled.Badge>
            <Styled.Badge>{account.currency}</Styled.Badge>
          </>
        )}
        {account.isInUse ? (
          <Styled.Badge $isActive>
            {translate('COMMON.LABELS.ACTIVE')}
          </Styled.Badge>
        ) : (
          <ChangeAccountButton account={account} challenge={challenge} />
        )}
      </Stack>
      <Styled.Info>
        <Styled.Portfolio>
          <Styled.PortfolioItem
            minWidth={`${getExtendedWidth('80', '1.05')}px`}
          >
            <Typography>{translate('COMMON.LABELS.P_L')}</Typography>
            <Typography>{accountCalculatedValues.pl}</Typography>
          </Styled.PortfolioItem>
          <Styled.PortfolioItem
            minWidth={`${getExtendedWidth('90', '1.05')}px`}
          >
            <Typography>{translate('COMMON.LABELS.EQUITY')}</Typography>
            <Typography>{accountCalculatedValues.equity}</Typography>
          </Styled.PortfolioItem>
          <Styled.PortfolioItem
            minWidth={`${getExtendedWidth('100', '1.05')}px`}
          >
            <Typography>{translate('COMMON.LABELS.FREE_MARGIN')}</Typography>
            <Typography>{accountCalculatedValues.freeMargin}</Typography>
          </Styled.PortfolioItem>
          <Styled.PortfolioItem
            minWidth={`${getExtendedWidth('100', '1.1', ['it', 'de', 'pl'])}px`}
          >
            <Typography>{translate('COMMON.LABELS.USED_MARGIN')}</Typography>
            <Typography>{accountCalculatedValues.usedMargin}</Typography>
          </Styled.PortfolioItem>
          <Styled.PortfolioItem
            minWidth={`${getExtendedWidth('100', '1.05', ['it'])}px`}
          >
            <Typography>{translate('COMMON.LABELS.MARGIN_LEVEL')}</Typography>
            <Typography>{accountCalculatedValues.marginLevel}</Typography>
          </Styled.PortfolioItem>
          {shouldShowLeverage && (
            <Styled.PortfolioItem
              minWidth={`${getExtendedWidth('120', '1.05')}px`}
            >
              <Typography>{translate('TRADING.LEVERAGE')}</Typography>
              <Typography>{accountCalculatedValues.leverage}</Typography>
            </Styled.PortfolioItem>
          )}
          {isLiveAccount && (
            <Styled.PortfolioItem
              minWidth={`${getExtendedWidth('165', '1.05')}px`}
            >
              <Typography>
                {translate('COMMON.LABELS.AVAILABLE_FOR_WITHDRAWAL')}
              </Typography>
              <Typography>
                {accountCalculatedValues.availableForWithdrawal}
              </Typography>
            </Styled.PortfolioItem>
          )}
        </Styled.Portfolio>
        <Stack direction='row' spacing='10px'>
          {isLiveAccount && <AddFundsButton account={account} />}
          {!isChallengeAccountType && (
            <AccountStatementButton account={account} />
          )}
          {shouldShowLeverage && <ChangeLeverageButton account={account} />}
        </Stack>
      </Styled.Info>
    </Styled.Account>
  );
});
